import {
  SET_BUSINESS,
  SET_COUNT,
  SET_SINGLE,
  CLEAR_SINGLE,
  SET_CATEGORIES,
  SET_MAGAZINES,
} from "../actions/business";

const initialState = {
  availablebusinesses: [],
  selectedBusiness: [],
  categories: [],
  count: 0,
  magazines: [],
};

const client = (state = initialState, action) => {
  switch (action.type) {
    case SET_BUSINESS:
      return {
        availablebusinesses: action.clients,
        selectedBusiness: state.selectedBusiness,
        categories: state.categories,
        count: state.count,
        magazines: state.magazines,
      };

    case SET_COUNT:
      return {
        availablebusinesses: state.availablebusinesses,
        selectedBusiness: state.selectedBusiness,
        categories: state.categories,
        count: action.count,
        magazines: state.magazines,
      };
    case SET_SINGLE:
      return {
        availablebusinesses: state.availablebusinesses,
        selectedBusiness: action.client,
        categories: state.categories,
        count: state.count,
        magazines: state.magazines,
      };
    case CLEAR_SINGLE:
      return {
        availablebusinesses: state.availablebusinesses,
        selectedBusiness: initialState.selectedBusiness,
        categories: state.categories,
        count: state.count,
        magazines: state.magazines,
      };
    case SET_CATEGORIES:
      return {
        availablebusinesses: state.availablebusinesses,
        selectedBusiness: state.selectedBusiness,
        categories: action.categories,
        count: state.count,
        magazines: state.magazines,
      };
    case SET_MAGAZINES:
      return {
        availablebusinesses: state.availablebusinesses,
        selectedBusiness: state.selectedBusiness,
        categories: state.categories,
        count: state.count,
        magazines: action.magazines,
      };
    default:
      return state;
  }
};

export default client;
