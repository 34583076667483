class Clients {
    constructor(fname, lname, email, mailingList, start) {
        this.fname = fname;
        this.lname = lname;
        this.email = email
        this.mailingList = mailingList;
        this.start = start
    }
}

export default Clients;