class Business {
  constructor(
    id,
    name,
    town,
    postcode,
    phone,
    end,
    title,
    description,
    main_image,
    category,
    website,
    facebook,
    twitter,
    instagram,
    instructions,
    directoryTitle,
    discountAvailable,
    discountCode,
    backgroundImage,
    updateAt,
    featured
  ) {
    this.id = id;
    this.name = name;
    this.town = town;
    this.postcode = postcode;
    this.phone = phone;
    this.end = end;
    this.title = title;
    this.description = description;
    this.heroImageUrl = main_image;
    this.category = category;
    this.website = website;
    this.facebook = facebook;
    this.twitter = twitter;
    this.instagram = instagram;
    this.instructions = instructions;
    this.discountAvailable = discountAvailable;
    this.directoryTitle = directoryTitle;
    this.discountCode = discountCode;
    this.backgroundImage = backgroundImage;
    this.updatedAt = updateAt;
    this.featured = featured;
  }
}

export default Business;
