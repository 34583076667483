import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchInput from "../../components/UI/SearchInput";

import { getClients } from "../../store/actions/clients";
import "./ClientsScreen.css";
import checkIcon from "../../assets/icons8-checkmark-50.png";
import upIcon from "../../assets/icons8-up-arrow-64.png";
import downIcon from "../../assets/icons8-down-arrow-64.png";

function useAsyncRef(initialState) {
  const start = useRef(initialState);
  const [, forceRender] = useState(false);

  function updateStart(newState) {
    start.current = newState;
    forceRender((s) => !s);
  }
  return [start, updateStart];
}

const ClientsScreen = () => {
  const [error, setError] = useState(false);
  const [, setIsLoading] = useState(false);
  const [pageCount, setPageCount] = useAsyncRef(1);
  const [filterType, setFilterType] = useAsyncRef("");
  const [direction, setDirection] = useState("");
  const [perPage, setPerPage] = useState(10);

  const user = useSelector((state) => state.auth);
  const clients = useSelector((state) => state.clients.clients);
  const totalCount = useSelector((state) => state.clients.count);
  const dispatch = useDispatch();

  const FORM_INPUT_UPDATE = "FORM_INPUT_UPDATE";

  const formReducer = (state, action) => {
    if (action.type === FORM_INPUT_UPDATE) {
      const updatedValues = {
        ...state.inputValues,
        [action.input]: action.value,
      };
      return {
        inputValues: updatedValues,
      };
    }
    return state;
  };
  const [formState, dispatchFormState] = useReducer(formReducer, {
    inputValues: {
      search: "",
    },
  });

  const searchHandler = useCallback(async () => {
    if (user.token) {
      try {
        await dispatch(
          getClients(
            formState.inputValues.search,
            pageCount.current,
            filterType.current,
            perPage,
            direction,
            user.token
          )
        );
      } catch (err) {
        setError(err.message);
        setIsLoading(false);
      }
    }
  }, [
    dispatch,
    formState.inputValues.search,
    pageCount,
    user.token,
    filterType,
    perPage,
    direction,
  ]);

  useEffect(() => {
    if (error) {
      //alert('An error occured', error, [{text: "Ok"}])
      console.log({ error });
    }
  }, [error]);

  const inputChangeHandler = useCallback(
    (inputIdentifier, inputValue) => {
      dispatchFormState({
        type: FORM_INPUT_UPDATE,
        value: inputValue,
        input: inputIdentifier,
      });
    },
    [dispatchFormState]
  );

  useEffect(() => {
    searchHandler();
  }, [formState.inputValues.search, pageCount, filterType, searchHandler]);

  const pageHandler = (btn) => {
    switch (btn) {
      case "first":
        setPageCount(1);
        break;
      case "prev":
        if (pageCount.current > 1) {
          setPageCount(pageCount.current - 1);
        }
        break;
      case "next":
        if (pageCount.current < Math.ceil(totalCount / perPage)) {
          setPageCount(pageCount.current + 1);
        }
        break;
      case "last":
        setPageCount(Math.ceil(totalCount / perPage));
        break;
      default:
        console.log("default");
    }
    searchHandler();
  };

  const filterHandler = (type) => {
    if (filterType.current !== type) {
      setFilterType(type);
      setDirection("ASC");
    } else {
      if (direction === "DESC") {
        setFilterType("");
        setDirection("");
      }
      if (direction === "ASC") {
        setDirection("DESC");
      }
    }
    searchHandler();
  };

  const formattedDate = (start) => {
    const date = new Date(start);
    return date.toLocaleDateString();
  };

  return (
    <>
      <div className="client__screen">
        <div className="contactsAdvanceBtnsContainer">
          <div
            className="offer__btns"
            onClick={() => {
              pageHandler("first");
            }}
          >
            &#8810; First
          </div>
          <div
            className="offer__btns"
            onClick={() => {
              pageHandler("prev");
            }}
          >
            &#8826; Prev
          </div>
          <div className="offer__btns pageCount">
            {pageCount.current} of {Math.ceil(totalCount / perPage)}
          </div>
          <div
            className="offer__btns"
            onClick={() => {
              pageHandler("next");
            }}
          >
            Next &#8827;
          </div>
          <div
            className="offer__btns"
            onClick={() => {
              pageHandler("last");
            }}
          >
            Last &#8811;
          </div>
        </div>

        <div className="newClient__mainContainer">
          <div>
            <SearchInput
              id="search"
              label="Search"
              type="text"
              autoCapitalize="none"
              onInputChange={inputChangeHandler}
            />
          </div>
        </div>

        <div className="client__totalMembers">{totalCount} members</div>
      </div>
      <div className="client__searchResultsContainer">
        <div
          className="client__searchResultItem name"
          onClick={() => {
            filterHandler("fname");
          }}
        >
          <div>First Name</div>{" "}
          {filterType.current === "fname" && direction === "ASC" ? (
            <img className="icon" src={upIcon} alt="up" />
          ) : filterType.current === "fname" && direction === "DESC" ? (
            <img className="icon" src={downIcon} alt="down" />
          ) : null}
        </div>
        <div
          className="client__searchResultItem name"
          onClick={() => {
            filterHandler("lname");
          }}
        >
          <div>Last Name</div>{" "}
          {filterType.current === "lname" && direction === "ASC" ? (
            <img className="icon" src={upIcon} alt="up" />
          ) : filterType.current === "lname" && direction === "DESC" ? (
            <img className="icon" src={downIcon} alt="down" />
          ) : null}
        </div>
        <div
          className="client__searchResultItem email"
          onClick={() => {
            filterHandler("email");
          }}
        >
          <div>Email</div>{" "}
          {filterType.current === "email" && direction === "ASC" ? (
            <img className="icon" src={upIcon} alt="up" />
          ) : filterType.current === "email" && direction === "DESC" ? (
            <img className="icon" src={downIcon} alt="down" />
          ) : null}
        </div>
        <div
          className="client__searchResultItem signUp"
          onClick={() => {
            filterHandler("created_at");
          }}
        >
          <div>Signup Date</div>{" "}
          {filterType.current === "created_at" && direction === "ASC" ? (
            <img className="icon" src={upIcon} alt="up" />
          ) : filterType.current === "created_at" && direction === "DESC" ? (
            <img className="icon" src={downIcon} alt="down" />
          ) : null}
        </div>
        <div
          className="client__searchResultItem mailinglist"
          onClick={() => {
            filterHandler("mailinglist");
          }}
        >
          <div>Mailing List</div>{" "}
          {filterType.current === "mailinglist" && direction === "ASC" ? (
            <img className="icon" src={upIcon} alt="up" />
          ) : filterType.current === "mailinglist" && direction === "DESC" ? (
            <img className="icon" src={downIcon} alt="down" />
          ) : null}
        </div>
      </div>

      {clients.map((client) => (
        <div className="client__searchResultsContainer" key={client.email}>
          <div className="client__searchResultItem name">{client.fname}</div>
          <div className="client__searchResultItem name">{client.lname}</div>
          <div className="client__searchResultItem email">{client.email}</div>
          <div className="client__searchResultItem signUp">
            {formattedDate(client.start)}
          </div>
          <div className="client__searchResultItem mailinglist">
            {client.mailingList === "1" ? (
              <img className="icon" src={checkIcon} alt="logo" />
            ) : null}
          </div>
        </div>
      ))}
      <div className="contactsAdvanceBtnsContainer client__count__control">
        <span className="client__count">Count per page</span>
        <div
          className="offer__btns"
          onClick={() => {
            setPerPage(10);
          }}
        >
          10
        </div>
        <div
          className="offer__btns"
          onClick={() => {
            setPerPage(25);
          }}
        >
          25
        </div>

        <div
          className="offer__btns"
          onClick={() => {
            setPerPage(50);
          }}
        >
          50
        </div>
        <div
          className="offer__btns"
          onClick={() => {
            setPerPage(100);
          }}
        >
          100
        </div>
      </div>
    </>
    // <div className="client__screen">
    //   <div className="newClient__mainContainer">
    //     <div>
    //       <SearchInput
    //         id="search"
    //         label="Search"
    //         type="text"
    //         autoCapitalize="none"
    //         onInputChange={inputChangeHandler}
    //       />
    //     </div>
    //   </div>
    //   <div className="client__newClientContainer">
    //     <div className="client__header">
    //       <p>Newest Clients</p>
    //     </div>
    //     <div className="client__listContainer">
    //       {clients.map((client) => (
    //         <div
    //           className="client__titleContainer"
    //           onClick={() => {
    //             clientHandler(client.email);
    //           }}
    //           key={client.email}
    //         >
    //           <div className="client__name">
    //             <div className="client__listItem" key={client.key}>
    //               <p>
    //                 {client.fname} {client.lname}
    //               </p>
    //             </div>
    //           </div>
    //         </div>
    //       ))}
    //     </div>
    //   </div>
    //   <div className="client__newClientContainer">
    //     <div className="client__header">
    //       <p>Selected Client</p>
    //     </div>
    //     <div className="client__body">
    //       <div>
    //         <div className="client__label">First Name</div>
    //         <div className="client__inputStyle">
    //           {ind.current && ind.current.fname}
    //         </div>
    //       </div>
    //       <div>
    //         <div className="client__label">Last Name</div>
    //         <div className="client__inputStyle">
    //           {ind.current && ind.current.lname}
    //         </div>
    //       </div>
    //       <div>
    //         <div className="client__label">Email</div>
    //         <div className="client__inputStyle">
    //           {ind.current && ind.current.email}
    //         </div>
    //       </div>
    //       <div>
    //         <div className="client__label">Sign up date</div>
    //         <div className="client__inputStyle">
    //           {ind.current && ind.current.start}
    //         </div>
    //       </div>
    //       <div>
    //         <div className="client__label">Mailing list</div>
    //         <div className="client__inputStyle client__bottom">
    //           {ind.current.fname && ind.current.mailingList && "Subscribed"}
    //           {ind.current.fname &&
    //             !ind.current.mailingList &&
    //             "Not subscribed"}
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   <div className="client__newClientContainer">
    //     <div className="client__header"></div>
    //     <div className="client__numbers">
    //       Number of clients currently registered..
    //     </div>
    //     <div className="client__totalNumber">{totalCount}</div>
    //   </div>
    // </div>
  );
};

export default ClientsScreen;
