import React, { useCallback, useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { Button } from "../../components/UI/Button";
import { updateBusiness, deleteBusiness } from "../../store/actions/business";
import "./EditOffersScreen.css";
import DatePicker from "react-date-picker";
import Input from "../../components/UI/Input";
import ToggleButton from "../../components/UI/ToggleButton";

const EditOffersScreen = (props) => {
  let query = new URLSearchParams(useLocation().search);
  const id = query.get("id");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [newHero, setNewHero] = useState("");
  const [newBtmLeft, setNewBackgroungImage] = useState("");
  const [imageHeroUrl, setImageHeroUrl] = useState("");
  const [backgroundImageUrl, setBackgroundImageUrl] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [categoryId, setCategoryId] = useState([]);
  const [date, setDate] = useState("");
  const [endDate, setEndDate] = useState(new Date());
  const [showModal, setShowModal] = useState(false);

  const user = useSelector((state) => state.auth);
  const business = useSelector((state) => state.business.selectedBusiness);
  const categories = useSelector((state) => state.business.categories);
  const dispatch = useDispatch();
  const history = useHistory();

  const FORM_INPUT_UPDATE = "FORM_INPUT_UPDATE";
  const FORM_UPDATE_ALL = "FORM_UPDATE_ALL";

  const formReducer = (state, action) => {
    if (action.type === FORM_INPUT_UPDATE) {
      const updatedValues = {
        ...state.inputValues,
        [action.input]: action.value,
      };
      const updatedValidities = {
        ...state.inputValidities,
        [action.input]: action.isValid,
      };
      let updatedFormIsValid = true;
      for (const key in updatedValidities) {
        updatedFormIsValid = updatedFormIsValid && updatedValidities[key];
      }
      return {
        formIsValid: updatedFormIsValid,
        inputValidities: updatedValidities,
        inputValues: updatedValues,
      };
    }
    if (action.type === FORM_UPDATE_ALL) {
      const updatedValues = {
        name: action.value.name,
        town: action.value.town,
        postcode: action.value.postcode,
        phone: action.value.phone,
        end: action.value.end,
        title: action.value.title,
        description: action.value.description,
        heroImageUrl: action.value.heroImageUrl,
        backgroundImage: action.value.backgroundImage,
        category: action.value.category,
        website: action.value.website,
        facebook: action.value.facebook,
        twitter: action.value.twitter,
        instagram: action.value.instagram,
        instructions: action.value.instructions,
        discountAvailable: action.value.discountAvailable,
        directoryTitle: action.value.directoryTitle,
        discountCode: action.value.discountCode,
        featured: action.value.featured,
      };
      const updatedValidities = {
        name: true,
        town: true,
        postcode: true,
        phone: true,
        end: true,
        title: true,
        description: true,
        heroImageUrl: true,
        backgroundImage: true,
        category: true,
        website: true,
        facebook: true,
        twitter: true,
        instagram: true,
        instructions: true,
        discountAvailable: true,
        directoryTitle: true,
        disscountCode: true,
        featured: true,
      };
      const updatedFormIsValid = true;

      return {
        formIsValid: updatedFormIsValid,
        inputValidities: updatedValidities,
        inputValues: updatedValues,
      };
    }
    return state;
  };

  const [formState, dispatchFormState] = useReducer(formReducer, {
    inputValues: {
      name: business ? business.name : "",
      town: business ? business.town : "",
      postcode: business ? business.postcode : "",
      phone: business ? business.phone : "",
      end: business ? business.end : "",
      title: business ? business.title : "",
      description: business ? business.description : "",
      heroImageUrl: business ? business.heroImageUrl : "",
      backgroundImage: business ? business.backgroundImage : "",
      category: business ? business.category : "",
      website: business ? business.website : "",
      facebook: business ? business.facebook : "",
      twitter: business ? business.twitter : "",
      instagram: business ? business.instagram : "",
      instructions: business ? business.instructions : "",
      discountAvailable: business ? business.discountAvailable : "",
      directoryTitle: business ? business.directoryTitle : "",
      discountCode: business ? business.discountCode : "",
      featured: business ? business.featured : "",
    },
    inputValidities: {
      name: !!business.name,
      town: !!business.town,
      postcode: !!business.postcode,
      phone: !!business.phone,
      end: !!business.end,
      title: !!business.title,
      description: !!business.description,
      heroImageUrl: !!business.heroImageUrl,
      backgroundImage: !!business.backgroundImage,
      category: !!business.category,
      website: !!business.website,
      facebook: !!business.facebook,
      twitter: !!business.twitter,
      instagram: !!business.instagram,
      instructions: !!business.instructions,
      discountAvailable: !!business.discountAvailable,
      directoryTitle: !!business.directoryTitle,
      discountCode: !!business.discountCode,
      featured: !!business.featured,
    },
    formIsValid: false,
  });

  const submitHandler = async (e) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);
    let action;
    action = updateBusiness(
      id,
      formState.inputValues.name,
      formState.inputValues.town,
      formState.inputValues.postcode,
      formState.inputValues.phone,
      formState.inputValues.end,
      formState.inputValues.title,
      formState.inputValues.description,
      formState.inputValues.heroImageUrl,
      formState.inputValues.backgroundImage,
      newHero,
      newBtmLeft,
      user.token,
      formState.inputValues.category,
      formState.inputValues.website,
      formState.inputValues.facebook,
      formState.inputValues.twitter,
      formState.inputValues.instagram,
      formState.inputValues.instructions,
      formState.inputValues.discountAvailable,
      formState.inputValues.directoryTitle,
      formState.inputValues.discountCode,
      formState.inputValues.featured
    );

    try {
      await dispatch(action).then(() => {
        setIsLoading(false);
        history.push("/offers");
      });
    } catch (err) {
      setError(err.message);
      setIsLoading(false);
    }
  };

  const inputChangeHandler = useCallback(
    (inputIdentifier, inputValue, inputValidity) => {
      if (inputIdentifier === "discountAvailable" && inputValue === false) {
        setShowModal(false);
      }
      dispatchFormState({
        type: FORM_INPUT_UPDATE,
        value: inputValue,
        isValid: inputValidity,
        input: inputIdentifier,
      });
    },
    [dispatchFormState]
  );

  useEffect(() => {
    if (error) {
      //alert('An error occured', error, [{text: "Ok"}])
      console.log({ error });
    }
  }, [error]);

  useEffect(() => {
    if (id) {
      const setInitState = () => {
        setIsLoading(false);
        setImageHeroUrl(business.heroImageUrl);
        setBackgroundImageUrl(business.backgroundImage);
        setCategoryId(business.category);
        setEndDate(new Date(business.end));
        dispatchFormState({
          type: FORM_UPDATE_ALL,
          value: {
            name: business.name,
            town: business.town,
            postcode: business.postcode,
            phone: business.phone,
            end: business.end,
            title: business.title,
            description: business.description,
            heroImageUrl: business.heroImageUrl,
            backgroundImage: business.backgroundImage,
            category: business.category,
            website: business.website,
            facebook: business.facebook,
            twitter: business.twitter,
            instagram: business.instagram,
            instructions: business.instructions,
            discountAvailable: business.discountAvailable,
            directoryTitle: business.directoryTitle,
            discountCode: business.discountCode,
            featured: business.featured,
          },
        });
      };
      setInitState();
    }
  }, [isLoading, business, id]);

  const imageUploadHandler = async (file, setState) => {
    const formData = new FormData();
    formData.append("fileUpload", file[0]);
    const response = await fetch(
      "https://app.disclosurediscounts.co.uk/api/multiimageupload.php",
      {
        method: "POST",
        headers: {
          Accept: "*/*",
          "Accept-Encoding": "gzip,deflate,br",
          Connection: "keep-alive",
        },
        body: formData,
      }
    ).catch(console.error);
    const resData = await response.json();
    if (resData.status) {
      setState(resData.imagepath);
    }
  };

  const uploadHeroHandler = (event) => {
    let reader = new FileReader();
    let file = event.target.files;
    imageUploadHandler(file, setNewHero).then((response) => () => {});
    reader.onloadend = () => {
      setNewHero(file);
      setImageHeroUrl(reader.result);
    };
    reader.readAsDataURL(file[0]);

    dispatchFormState({
      type: FORM_INPUT_UPDATE,
      value: event.target.value,
      isValid: true,
      input: "heroImageUrl",
    });
  };
  const uploadBackgroundImageHandler = (event) => {
    let reader = new FileReader();
    let file = event.target.files;
    imageUploadHandler(file, setNewBackgroungImage).then(
      (response) => () => {}
    );
    reader.onloadend = () => {
      setNewBackgroungImage(file[0]);
      setBackgroundImageUrl(reader.result);
    };
    reader.readAsDataURL(file[0]);

    dispatchFormState({
      type: FORM_INPUT_UPDATE,
      value: event.target.value,
      isValid: true,
      input: "backgroundImage",
    });
  };

  useEffect(() => {
    setShowForm(false);
    if (id === business.id) {
      setShowForm(true);
    }
    if (!id) {
      setShowForm(true);
    }
  }, [id, business.id]);

  const deleteHandler = () => {
    dispatch(deleteBusiness(id, user.token));
    history.push("/offers");
  };

  const selectChangeHandler = (e) => {
    let categories;
    const categoryExists = categoryId?.filter(
      (item) => item === e.target.value
    );

    if (categoryExists?.length > 0) {
      categories = categoryId.filter((item) => item !== e.target.value);
    } else {
      categories = categoryId;
      categories.push(e.target.value);
    }

    setCategoryId(categories);

    dispatchFormState({
      type: FORM_INPUT_UPDATE,
      value: categories,
      isValid: true,
      input: "category",
    });
  };

  useEffect(() => {
    dispatchFormState({
      type: FORM_INPUT_UPDATE,
      value: endDate ? endDate.toString() : "",
      isValid: true,
      input: "end",
    });
  }, [endDate]);
  console.log({ formState }, { newHero }, { newBtmLeft });

  let cloud;

  if (formState.inputValues?.instagram) {
    cloud = { type: "instagram", url: formState.inputValues?.instagram };
  }
  if (formState.inputValues?.twitter) {
    cloud = { type: "twitter", url: formState.inputValues?.twitter };
  }
  if (formState.inputValues?.facebook) {
    cloud = { type: "facebook", url: formState.inputValues?.facebook };
  }
  if (formState.inputValues?.website) {
    cloud = { type: "website", url: formState.inputValues?.website };
  }

  useEffect(() => {
    if (formState?.inputValues?.end) {
      const dateArray = formState?.inputValues?.end?.split(" ");

      const monthsArray = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      const year = dateArray[3];
      const month =
        monthsArray.indexOf(dateArray[1]) < 9
          ? "0" + (monthsArray.indexOf(dateArray[1]) + 1).toString()
          : (monthsArray.indexOf(dateArray[1]) + 1).toString();
      const day = dateArray[2];
      setDate(day + "/" + month + "/" + year);
    }
  }, [formState.inputValues.end]);

  return (
    <div className="editoffer__container">
      {id ? (
        <div className="editoffer__header">
          <div>
            <h1>Update Offer</h1>
          </div>
          <div className="editoffer__buttons">
            <Button
              buttonSize="btn--small"
              buttonColor="green"
              onClick={submitHandler}
            >
              Update Offer
            </Button>
            <Button
              buttonSize="btn--small"
              buttonColor="red"
              onClick={deleteHandler}
            >
              Delete Offer
            </Button>
          </div>
        </div>
      ) : (
        <div className="editoffer__header">
          <h1>Create New Offer</h1>
          <Button
            buttonSize="btn--small"
            buttonColor="green"
            onClick={submitHandler}
          >
            Create Offer
          </Button>
        </div>
      )}
      <form className="offerForm">
        {showForm && (
          <div className="editoffer__wrapper">
            <div className="editoffer__formContainer">
              <Input
                id="name"
                label="Name"
                type="text"
                required
                autoCapitalize="none"
                errorText="Please enter a name."
                onInputChange={inputChangeHandler}
                initialValue={business ? business.name : ""}
                initiallyValid={!!business}
                updatedValue={business ? business.name : ""}
              />
              <Input
                id="town"
                label="Town"
                type="text"
                required
                autoCapitalize="none"
                errorText="Please enter a town."
                onInputChange={inputChangeHandler}
                initialValue={business ? business.town : ""}
                initiallyValid={!!business}
                updatedValue={business ? business.town : ""}
              />
              <Input
                id="postcode"
                label="Postcode"
                type="text"
                postcode
                required
                autoCapitalize="all"
                errorText="Please enter a valid postcode."
                onInputChange={inputChangeHandler}
                initialValue={business ? business.postcode : ""}
                initiallyValid={!!business}
                updatedValue={business ? business.postcode : ""}
              />
              <Input
                id="phone"
                label="Phone"
                type="tel"
                phone
                required
                autoCapitalize="none"
                errorText="Please enter a valid phone number."
                onInputChange={inputChangeHandler}
                initialValue={business ? business.phone : ""}
                initiallyValid={!!business}
                updatedValue={business ? business.phone : ""}
              />
              <div className="spacer"></div>
              <Input
                id="website"
                label="Website address"
                type="text"
                autoCapitalize="none"
                errorText="Please enter a valid web address."
                onInputChange={inputChangeHandler}
                initialValue={business ? business.website : ""}
                initiallyValid={!!business}
                updatedValue={business ? business.website : ""}
              />
              <Input
                id="facebook"
                label="Facebook address"
                type="text"
                autoCapitalize="none"
                errorText="Please enter a valid phone number."
                onInputChange={inputChangeHandler}
                initialValue={business ? business.facebook : ""}
                initiallyValid={!!business}
                updatedValue={business ? business.facebook : ""}
              />
              <Input
                id="twitter"
                label="Twitter Address"
                type="text"
                autoCapitalize="none"
                errorText="Please enter a valid Twitter address."
                onInputChange={inputChangeHandler}
                initialValue={business ? business.twitter : ""}
                initiallyValid={!!business}
                updatedValue={business ? business.twitter : ""}
              />
              <Input
                id="instagram"
                label="Instagram address"
                type="text"
                autoCapitalize="none"
                errorText="Please enter a valid instagram address."
                onInputChange={inputChangeHandler}
                initialValue={business ? business.instagram : ""}
                initiallyValid={!!business}
                updatedValue={business ? business.instagram : ""}
              />
              <div className="spacer"></div>
              <div className="editoffer_upload_btn_wrapper">
                <span className="editoffer__imageLabel">
                  Change Logo image (120px x 120px)
                </span>
                <input
                  id="main_image"
                  type="file"
                  onChange={uploadHeroHandler}
                />
              </div>

              <div className="spacer"></div>
              <div className="editoffer_upload_btn_wrapper">
                <span className="editoffer__imageLabel">
                  Change Background image (375px x 667px)
                </span>
                <input
                  id="backgroundImage"
                  type="file"
                  onChange={uploadBackgroundImageHandler}
                />
              </div>
              <div className="spacer"></div>
            </div>

            <div className="editoffer__formContainer">
              <Input
                id="title"
                label="Offer title"
                type="text"
                required
                autoCapitalize="none"
                errorText="Please enter a offer title."
                onInputChange={inputChangeHandler}
                initialValue={business ? business.title : ""}
                initiallyValid={!!business}
                updatedValue={business ? business.title : ""}
              />
              <Input
                id="instructions"
                label="Claim instructions"
                type="text"
                required
                autoCapitalize="none"
                errorText="Please enter claim instructions."
                onInputChange={inputChangeHandler}
                initialValue={business ? business.instructions : ""}
                initiallyValid={!!business}
                updatedValue={business ? business.instructions : ""}
              />
              <Input
                id="description"
                label="Offer description"
                type="text"
                textarea
                required
                autoCapitalize="none"
                errorText="Please enter a offer description."
                onInputChange={inputChangeHandler}
                initialValue={business ? business.description : ""}
                initiallyValid={!!business}
                updatedValue={business ? business.description : ""}
              />
              <span className="inputlabel">Categories</span>
              <select
                className="select-css"
                value={categoryId}
                multiple
                onChange={selectChangeHandler}
              >
                {categories.map((category) => (
                  <option value={category.id} key={category.id}>
                    {category.username}
                  </option>
                ))}
              </select>
              <div className="spacer"></div>
              <div>
                <h4>Featured on Front page Carousel</h4>
              </div>
              <ToggleButton
                id="featured"
                name="Featured"
                onInputChange={inputChangeHandler}
                initialValue={business ? business.featured : ""}
              />

              <div className="spacer"></div>
              <div>
                <h4>Pop up Discount Voucher Details</h4>
              </div>
              <ToggleButton
                id="discountAvailable"
                name="Discount Available"
                onInputChange={inputChangeHandler}
                initialValue={business ? business.discountAvailable : ""}
              />

              <div className="inputlabel">Offer end date</div>
              <DatePicker
                name="end"
                onChange={setEndDate}
                value={endDate}
                className="datePicker"
                minDate={new Date()}
                required
              />
              <Input
                id="directoryTitle"
                label="Directory title"
                type="text"
                required
                autoCapitalize="none"
                errorText="Please enter a directory title."
                onInputChange={inputChangeHandler}
                initialValue={business ? business.directoryTitle : ""}
                initiallyValid={!!business}
                updatedValue={business ? business.directoryTitle : ""}
              />

              <Input
                id="discountCode"
                label="Discount Code - max length 10 characters"
                type="text"
                required
                autoCapitalize="none"
                errorText="Code can be no more than 10 characters."
                onInputChange={inputChangeHandler}
                initialValue={business ? business.discountCode : ""}
                initiallyValid={!!business}
                updatedValue={business ? business.discountCode : ""}
                maxLength={10}
              />

              <div className="editoffer_upload_btn_wrapper"></div>
            </div>
            <div className="editoffer__previewContainer">
              <div className="editoffer__preview">
                <div>
                  <div className="editoffer__mobile_preview">
                    {business && (
                      <div
                        style={{
                          position: "relative",
                          backgroundImage: `url(${backgroundImageUrl})`,
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                          height: "100%",
                        }}
                      >
                        <div className="previewLogoContainer">
                          {imageHeroUrl && (
                            <img
                              className="previewLogo"
                              src={imageHeroUrl}
                              alt="logo"
                            />
                          )}
                        </div>
                        <div className="previewSpacer"></div>

                        {showModal &&
                        formState.inputValues.discountAvailable ? (
                          <div className="discountModalContainer">
                            <div className="discountVoucherTitleContainer">
                              <div
                                style={{
                                  paddingTop: 20,
                                }}
                              >
                                <div className="discountVoucherTitle">
                                  Discount
                                </div>
                                <div className="discountVoucherTitle">
                                  Voucher
                                </div>
                              </div>
                            </div>
                            <div className="voucherImageContainer">
                              <div className="voucherImage">
                                <div className="codeContainer">
                                  <span className="titleText">
                                    {formState.inputValues.directoryTitle} :
                                  </span>
                                  <span className="titleText">
                                    {formState.inputValues.discountCode}
                                  </span>
                                </div>
                                <div className="expiryDateContainer">
                                  <div className="expiryDateText">
                                    Expiry date: {date}
                                  </div>
                                </div>
                                <div className="expiryDateContainer">
                                  <div className="clipboardText">
                                    Click ticket to copy code to clipboard
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="voucherInfoContainer">
                              <div>
                                Visit our {cloud.type} {cloud.url}
                              </div>
                              <div>
                                Copy and paste the code to use this voucher
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="previewDescriptionContainer">
                            <div className="nameTextContainer">
                              {formState.inputValues.name}
                            </div>
                            <div className="nameTextContainer">
                              <div className="titleTextContainer">
                                {formState.inputValues.title}
                              </div>
                              <div className="titleTextContainer">
                                {formState.inputValues.instructions}
                              </div>
                            </div>
                            <div className="nameTextContainer">
                              <div className="offerTitleContainer">
                                Offer Available
                              </div>

                              <pre className="titleTextContainer">
                                {formState.inputValues.description}
                              </pre>
                            </div>
                          </div>
                        )}

                        {formState.inputValues.discountAvailable ? (
                          <div
                            className="discountButtonContainer"
                            onClick={() => {
                              setShowModal(!showModal);
                            }}
                          >
                            <div className="discountButtonText">
                              Get Discount
                            </div>
                          </div>
                        ) : null}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default EditOffersScreen;
