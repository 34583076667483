import Apikey from "../../constants/Apikey";
import { authenticate, logout } from "./auth";
import Clients from "../../models/clients";

export const SET_CLIENTS = "SET_CLIENTS";
export const SET_CLIENT_COUNT = "SET_CLIENT_COUNT";

const apiKey = Apikey.apiKey;
const baseUrl = Apikey.baseUrl;

export const getClients = (
  searchTerm,
  page,
  filterType,
  count,
  direction,
  token
) => {
  return async (dispatch) => {
    const response = await fetch(`${baseUrl}/admin/clients`, {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Accept-Encoding": "gzip,deflate,br",
        Connection: "keep-alive",
        apiKey: apiKey,
        token: token,
      },
      body: JSON.stringify({
        searchTerm: searchTerm,
        page: page,
        filterType: filterType,
        count: count,
        direction: direction,
      }),
    });
    if (!response.ok) {
      const errorResData = await response.json();
      console.log(errorResData);
      let message = "Something went wrong " + errorResData;
      throw new Error(message);
    } else {
      const resData = await response.json();

      dispatch(
        dispatch({
          type: SET_CLIENT_COUNT,
          count: resData.search.count,
        })
      );

      loadClients(resData.search.clients, dispatch);
      if (!resData.token) {
        dispatch(logout());
      } else {
        setToken(resData.token, dispatch);
      }
    }
  };
};

const setToken = (resData, dispatch) => {
  dispatch(
    authenticate(
      resData.id,
      resData.verification_code,
      resData.email,
      resData.username
    )
  );
};

const loadClients = (resData, dispatch) => {
  const clients = [];

  for (const key in resData) {
    clients.push(
      new Clients(
        resData[key].fname,
        resData[key].lname,
        resData[key].email,
        resData[key].mailingList,
        resData[key].created_at
      )
    );
  }

  dispatch(
    dispatch({
      type: SET_CLIENTS,
      clients: clients,
    })
  );
};
