import React, { useState, useEffect, useReducer } from 'react';
import './ToggleButton.css';


const INPUT_CHANGE = 'INPUT_CHANGE';

const inputReducer = (state, action) => {
    console.log(state);
    switch (action.type) {
        case INPUT_CHANGE:
            return {
                ...state,
                value: action.value,
                isValid: action.isValid,
                touched: true
            };

        default:
            return state;
    }
};

const ToggleButton = (props) => {


    const { onInputChange, id } = props;

    const [inputState, dispatch] = useReducer(inputReducer, {
        value: props.initialValue ? props.initialValue : false,
        isValid: true,
        touched: true
    });
    const [isSelected, setIsSelected] = useState(props.initialValue ? props.initialValue : false);

    useEffect(() => {
        if (inputState.touched) {
            onInputChange(id, inputState.value, inputState.isValid);
        }
    }, [inputState, onInputChange, id]);


    useEffect(() => {
        if (inputState.touched) {

            onInputChange(id, inputState.value, true);
        }
    }, [inputState, onInputChange, id]);

    const selectChangeHandler = () => {
        dispatch({ type: INPUT_CHANGE, value: !isSelected, isValid: true });
        setIsSelected(!isSelected)
    }

    return (
        <div>
            <div className="inputlabel">{props.name}</div>
            <div className="toggleButtonContainer" onClick={selectChangeHandler}>
                <div className="toggleButtonOffText">On</div>
                <div className="toggleButtonOnText">Off</div>
                <div className={isSelected ? "toggleButtonInner selected" : "toggleButtonInner"}>

                </div>
            </div>
        </div>

    );
};

export default ToggleButton;
