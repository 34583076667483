class Magazines {
  constructor(issue, url, date, town) {
    this.issue = issue;
    this.url = url;
    this.date = date;
    this.town = town;
  }
}

export default Magazines;
