import Business from "../../models/business";
import Categories from "../../models/categories";
import Magazines from "../../models/magazine";

import Apikey from "../../constants/Apikey";
import { authenticate, logout } from "./auth";

export const SET_BUSINESS = "SET_BUSINESS";
export const SET_COUNT = "SET_COUNT";
export const SET_SINGLE = "SET_SINGLE";
export const CLEAR_SINGLE = "CLEAR_SINGLE";
export const SET_CATEGORIES = "SET_CATEGORIES";
export const SET_MAGAZINES = "SET_MAGAZINES";

const apiKey = Apikey.apiKey;
const baseUrl = Apikey.baseUrl;

export const setClients = (loadedClients) => {
  return (dispatch) => {
    dispatch({
      type: SET_BUSINESS,
      clients: loadedClients,
    });
  };
};
export const setCategories = (categories) => {
  return (dispatch) => {
    dispatch({
      type: SET_CATEGORIES,
      categories: categories,
    });
  };
};
export const setMagazines = (magazines) => {
  return (dispatch) => {
    dispatch({
      type: SET_MAGAZINES,
      magazines: magazines,
    });
  };
};

const formatDate = (date) => {
  let dateObj = new Date(date);

  const d = dateObj.getDate();
  const m = dateObj.getMonth() + 1;
  const y = dateObj.getFullYear();
  return y + "-" + (m <= 9 ? "0" + m : m) + "-" + (d <= 9 ? "0" + d : d);
};

export const getBusinesses = (inputValue, pageCount, filterType, token) => {
  return async (dispatch) => {
    const response = await fetch(`${baseUrl}/admin/getbusinesses/`, {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Accept-Encoding": "gzip,deflate,br",
        Connection: "keep-alive",
        apiKey: apiKey,
        token: token,
      },
      body: JSON.stringify({
        input: inputValue,
        page: pageCount,
        filter: filterType,
      }),
    });

    if (!response.ok) {
      const errorResData = await response.json();
      console.log(errorResData);
      let message = "Something went wrong " + errorResData;
      throw new Error(message);
    }
    const resData = await response.json();
    loadBusinesses(resData.search, dispatch);
    if (!resData.token) {
      dispatch(logout());
    } else {
      setToken(resData.token, dispatch);
    }
  };
};

export const getBusinessById = (id, token) => {
  return async (dispatch) => {
    const response = await fetch(`${baseUrl}/admin/getbusiness/${id}`, {
      method: "GET",
      headers: {
        Accept: "*/*",
        "Accept-Encoding": "gzip,deflate,br",
        Connection: "keep-alive",
        apiKey: apiKey,
        token: token,
      },
    });

    if (!response.ok) {
      const errorResData = await response.json();
      console.log(errorResData);
      let message = "Something went wrong " + errorResData;
      throw new Error(message);
    }
    const resData = await response.json();
    let categories = [];
    for (const key in resData.search.categories) {
      categories.push(resData.search.categories[key].id);
    }
    console.log(resData.search.company);
    dispatch({
      type: SET_SINGLE,
      client: {
        id: resData.search.company.company_id,
        name: resData.search.company.name,
        town: resData.search.company.town,
        postcode: resData.search.company.postcode,
        phone: resData.search.company.phone,
        end: resData.search.company.end_date.replace(/-/g, "/"),
        title: resData.search.company.offer_title,
        description: resData.search.company.offer_desc,
        heroImageUrl: resData.search.company.main_image,
        category: categories,
        website: resData.search.company.website,
        facebook: resData.search.company.facebook,
        twitter: resData.search.company.twitter,
        instagram: resData.search.company.instagram,
        instructions: resData.search.company.offer_subtitle,
        directoryTitle: resData.search.company.directory_title,
        discountAvailable:
          resData.search.company.discount_available === "1" ? true : false,
        discountCode: resData.search.company.discount_code,
        backgroundImage: resData.search.company.background_image,
        updatedAt: resData.search.company.updated_at,
        featured: resData.search.company.featured === "1" ? true : false,
      },
    });

    if (!resData.token) {
      dispatch(logout());
    } else {
      setToken(resData.token, dispatch);
    }
  };
};

export const updateBusiness = (
  id,
  name,
  town,
  postcode,
  phone,
  end,
  title,
  description,
  oldHeroUrl,
  oldBackGroundImageUrl,
  newHeroImageUrl,
  newBackgroundImageUrl,
  token,
  category,
  website,
  facebook,
  twitter,
  instagram,
  instructions,
  discountAvailable,
  directoryTitle,
  discountCode,
  featured
) => {
  return async (dispatch) => {
    const response = await fetch(
      `${baseUrl}/admin/updatebusiness/${id ? id : ""}`,
      {
        method: "POST",
        headers: {
          Accept: "*/*",
          "Accept-Encoding": "gzip,deflate,br",
          Connection: "keep-alive",
          apiKey: apiKey,
          token: token,
        },
        body: JSON.stringify({
          name: name,
          town: town,
          postcode: postcode,
          phone: phone,
          end: formatDate(end),
          title: title,
          description: description,
          newHeroImageUrl: newHeroImageUrl ? newHeroImageUrl : oldHeroUrl,
          newBackgroundImageUrl: newBackgroundImageUrl
            ? newBackgroundImageUrl
            : oldBackGroundImageUrl,
          category: category,
          website: website,
          facebook: facebook,
          twitter: twitter,
          instagram: instagram,
          instructions: instructions,
          directoryTitle: directoryTitle,
          discountAvailable: discountAvailable,
          discountCode: discountCode,
          featured: featured,
        }),
      }
    );

    if (!response.ok) {
      const errorResData = await response.json();
      console.log(errorResData);
      let message = "Something went wrong " + errorResData;
      throw new Error(message);
    }
    const resData = await response.json();

    if (!id) {
      loadBusinesses(resData.search, dispatch);
    }
    if (!resData.token) {
      dispatch(logout());
    } else {
      setToken(resData.token, dispatch);
    }
  };
};

export const deleteBusiness = (id, token) => {
  return async (dispatch) => {
    const response = await fetch(`${baseUrl}/admin/deletebusiness/${id}`, {
      method: "GET",
      headers: {
        Accept: "*/*",
        "Accept-Encoding": "gzip,deflate,br",
        Connection: "keep-alive",
        apiKey: apiKey,
        token: token,
      },
    });

    if (!response.ok) {
      const errorResData = await response.json();
      console.log(errorResData);
      let message = "Something went wrong " + errorResData;
      throw new Error(message);
    } else {
      const resData = await response.json();

      loadBusinesses(resData.search, dispatch);
      if (!resData.token) {
        dispatch(logout());
      } else {
        setToken(resData.token, dispatch);
      }
    }
  };
};

export const getCategories = (token) => {
  return async (dispatch) => {
    const response = await fetch(`${baseUrl}/admin/categories`, {
      method: "GET",
      headers: {
        Accept: "*/*",
        "Accept-Encoding": "gzip,deflate,br",
        Connection: "keep-alive",
        apiKey: apiKey,
        token: token,
      },
    });

    if (!response.ok) {
      const errorResData = await response.json();
      console.log("error message", errorResData.search.error.message);
      let message = errorResData.search.error.message;
      throw new Error(message);
    } else {
      const resData = await response.json();

      loadCategories(resData.search, dispatch);
      if (!resData.token) {
        dispatch(logout());
      } else {
        setToken(resData.token, dispatch);
      }
    }
  };
};
export const deleteCategory = (id, token) => {
  return async (dispatch) => {
    const response = await fetch(`${baseUrl}/admin/category/${id}`, {
      method: "GET",
      headers: {
        Accept: "*/*",
        "Accept-Encoding": "gzip,deflate,br",
        Connection: "keep-alive",
        apiKey: apiKey,
        token: token,
      },
    });

    if (!response.ok) {
      const errorResData = await response.json();
      console.log(errorResData);
      let message = "Something went wrong " + errorResData;
      throw new Error(message);
    } else {
      const resData = await response.json();

      loadCategories(resData.search, dispatch);
      if (!resData.token) {
        dispatch(logout());
      } else {
        setToken(resData.token, dispatch);
      }
    }
  };
};
export const createCategory = (name, url, token) => {
  return async (dispatch) => {
    const response = await fetch(`${baseUrl}/admin/category/`, {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Accept-Encoding": "gzip,deflate,br",
        Connection: "keep-alive",
        apiKey: apiKey,
        token: token,
      },
      body: JSON.stringify({
        name: name,
        url: url,
      }),
    });

    if (!response.ok) {
      const errorResData = await response.json();

      let message = errorResData.search.error.message;
      throw new Error(message);
    } else {
      const resData = await response.json();
      console.log({ resData });
      loadCategories(resData.search, dispatch);
      if (!resData.token) {
        dispatch(logout());
      } else {
        setToken(resData.token, dispatch);
      }
    }
  };
};

export const getMagazines = (token, issue) => {
  return async (dispatch) => {
    const response = await fetch(
      `${baseUrl}/admin/magazines/${issue ? issue : ""}`,
      {
        method: "GET",
        headers: {
          Accept: "*/*",
          "Accept-Encoding": "gzip,deflate,br",
          Connection: "keep-alive",
          apiKey: apiKey,
          token: token,
        },
      }
    );

    if (!response.ok) {
      const errorResData = await response.json();
      console.log("error message", errorResData.search.error.message);
      let message = errorResData.search.error.message;
      throw new Error(message);
    } else {
      const resData = await response.json();

      loadMagazines(resData, dispatch);
      if (!resData.token) {
        dispatch(logout());
      } else {
        setToken(resData.token, dispatch);
      }
    }
  };
};

export const deleteMagazine = (issue, token) => {
  return async (dispatch) => {
    const response = await fetch(`${baseUrl}/admin/magazine/${issue}`, {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Accept-Encoding": "gzip,deflate,br",
        Connection: "keep-alive",
        apiKey: apiKey,
        token: token,
      },
    });

    if (!response.ok) {
      const errorResData = await response.json();
      console.log("error message", errorResData.search.error.message);
      let message = errorResData.search.error.message;
      throw new Error(message);
    } else {
      const resData = await response.json();

      loadMagazines(resData, dispatch);
      if (!resData.token) {
        console.log("logout");
        dispatch(logout());
      } else {
        setToken(resData.token, dispatch);
      }
    }
  };
};

export const updateMagazine = (issue, url, date, town, token) => {
  console.log({ issue }, { url }, { date }, { town }, { token });
  return async (dispatch) => {
    const response = await fetch(`${baseUrl}/admin/updateMagazine/`, {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Accept-Encoding": "gzip,deflate,br",
        Connection: "keep-alive",
        apiKey: apiKey,
        token: token,
      },
      body: JSON.stringify({
        issue,
        url,
        date: formatDate(date),
        town,
      }),
    });

    if (!response.ok) {
      const errorResData = await response.json();
      console.log("error message", errorResData.search.error.message);
      let message = errorResData.search.error.message;
      throw new Error(message);
    } else {
      const resData = await response.json();
      console.log({ resData });
      loadMagazines(resData, dispatch);
      if (!resData.token) {
        dispatch(logout());
      } else {
        setToken(resData.token, dispatch);
      }
    }
  };
};

const setToken = (resData, dispatch) => {
  dispatch(
    authenticate(
      resData.id,
      resData.verification_code,
      resData.email,
      resData.username
    )
  );
};

const loadBusinesses = (resData, dispatch) => {
  const loadedBusiness = [];
  let end;

  for (const key in resData.result) {
    end = new Date(resData.result[key].end_date.replace(/-/g, "/"));
    loadedBusiness.push(
      new Business(
        resData.result[key].company_id,
        resData.result[key].name,
        resData.result[key].town,
        resData.result[key].postcode,
        resData.result[key].phone,
        end.toDateString(),
        resData.result[key].offer_title,
        resData.result[key].offer_desc,
        resData.result[key].main_image,
        resData.result[key].category_id,
        resData.result[key].website,
        resData.result[key].facebook,
        resData.result[key].twitter,
        resData.result[key].instagram,
        resData.result[key].offer_subtitle,
        resData.result[key].directory_title,
        resData.result[key].discount_available === "1" ? true : false,
        resData.result[key].discount_code,
        resData.result[key].background_image,
        resData.result[key].updated_at,
        resData.result[key].featured === "1" ? true : false
      )
    );
  }
  dispatch(setClients(loadedBusiness));
  dispatch({
    type: SET_COUNT,
    count: resData.count,
  });
};

const loadCategories = (resData, dispatch) => {
  const categories = [];
  for (const key in resData.clients) {
    categories.push(
      new Categories(
        resData.clients[key].id,
        resData.clients[key].category_name,
        resData.clients[key].icon_url
      )
    );
  }
  dispatch(setCategories(categories));
};

const loadMagazines = (resData, dispatch) => {
  const magazines = [];
  for (const key in resData.search) {
    magazines.push(
      new Magazines(
        resData.search[key].issue,
        resData.search[key].url,
        resData.search[key].date,
        resData.search[key].town
      )
    );
  }
  dispatch(setMagazines(magazines));
};
