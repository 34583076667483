import React, { useCallback, useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { Button } from "../../components/UI/Button";
import {
  getMagazines,
  updateMagazine,
  deleteMagazine,
} from "../../store/actions/business";
import "./MagazineScreen.css";
import DatePicker from "react-date-picker";
import Input from "../../components/UI/Input";

const EditOffersScreen = (props) => {
  let query = new URLSearchParams(useLocation().search);
  const id = query.get("id");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [newHero, setNewHero] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [issueDate, setIssueDate] = useState(new Date());

  const user = useSelector((state) => state.auth);
  const business = useSelector((state) => state.business.selectedBusiness);
  const magazines = useSelector((state) => state.business.magazines);
  const dispatch = useDispatch();
  const history = useHistory();

  const FORM_INPUT_UPDATE = "FORM_INPUT_UPDATE";
  const FORM_UPDATE_ALL = "FORM_UPDATE_ALL";

  const formReducer = (state, action) => {
    if (action.type === FORM_INPUT_UPDATE) {
      const updatedValues = {
        ...state.inputValues,
        [action.input]: action.value,
      };
      const updatedValidities = {
        ...state.inputValidities,
        [action.input]: action.isValid,
      };
      let updatedFormIsValid = true;
      for (const key in updatedValidities) {
        updatedFormIsValid = updatedFormIsValid && updatedValidities[key];
      }
      return {
        formIsValid: updatedFormIsValid,
        inputValidities: updatedValidities,
        inputValues: updatedValues,
      };
    }
    if (action.type === FORM_UPDATE_ALL) {
      const updatedValues = {
        issue: action.value.issue,
        url: action.value.url,
        date: action.value.date,
        town: action.value.town,
      };
      const updatedValidities = {
        issue: true,
        url: true,
        date: true,
        town: true,
      };
      const updatedFormIsValid = true;

      return {
        formIsValid: updatedFormIsValid,
        inputValidities: updatedValidities,
        inputValues: updatedValues,
      };
    }
    return state;
  };

  const [formState, dispatchFormState] = useReducer(formReducer, {
    inputValues: {
      issue: business ? business.issue : "",
      url: business ? business.url : "",
      date: business ? business.date : "",
      town: business ? business.town : "",
    },
    inputValidities: {
      issue: !!business.issue,
      url: !!business.url,
      date: !!business.date,
      town: !!business.town,
    },
    formIsValid: false,
  });

  const submitHandler = async (e) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);
    let action;
    action = updateMagazine(
      formState.inputValues.issue,
      newHero,
      formState.inputValues.date,
      formState.inputValues.town,
      user.token
    );

    try {
      await dispatch(action).then(() => {
        setIsLoading(false);
        history.push("/magazine");
      });
    } catch (err) {
      setError(err.message);
      setIsLoading(false);
    }
  };

  const inputChangeHandler = useCallback(
    (inputIdentifier, inputValue, inputValidity) => {
      dispatchFormState({
        type: FORM_INPUT_UPDATE,
        value: inputValue,
        isValid: inputValidity,
        input: inputIdentifier,
      });
    },
    [dispatchFormState]
  );

  useEffect(() => {
    if (error) {
      //alert('An error occured', error, [{text: "Ok"}])
      console.log({ error });
    }
  }, [error]);

  useEffect(() => {
    if (id) {
      const setInitState = () => {
        setIsLoading(false);
        setIssueDate(new Date(business.date));
        dispatchFormState({
          type: FORM_UPDATE_ALL,
          value: {
            issue: business.issue,
            url: business.url,
            date: business.date,
            town: business.town,
          },
        });
      };
      setInitState();
    }
  }, [isLoading, business, id]);

  useEffect(() => {
    const loadMagazines = async () => {
      if (user.token) {
        try {
          await dispatch(getMagazines(user.token));
        } catch (err) {
          setError(err.message);
          setIsLoading(false);
        }
      }
    };
    loadMagazines();
  }, []);

  const imageUploadHandler = async (file, setState) => {
    const formData = new FormData();
    formData.append("fileUpload", file[0]);
    const response = await fetch(
      "https://app.disclosurediscounts.co.uk/api/multiimageupload.php",
      {
        method: "POST",
        headers: {
          Accept: "*/*",
          "Accept-Encoding": "gzip,deflate,br",
          Connection: "keep-alive",
        },
        body: formData,
      }
    ).catch(console.error);
    const resData = await response.json();
    if (resData.status) {
      setState(resData.imagepath);
    }
  };

  const uploadHeroHandler = (event) => {
    let reader = new FileReader();
    let file = event.target.files;
    imageUploadHandler(file, setNewHero).then((response) => () => {});
    reader.onloadend = () => {
      setNewHero(file);
    };
    reader.readAsDataURL(file[0]);

    dispatchFormState({
      type: FORM_INPUT_UPDATE,
      value: event.target.value,
      isValid: true,
      input: "url",
    });
  };

  useEffect(() => {
    setShowForm(false);
    if (id === business.id) {
      setShowForm(true);
    }
    if (!id) {
      setShowForm(true);
    }
  }, [id, business.id]);

  const deleteHandler = (issue) => (e) => {
    e.preventDefault();
    dispatch(deleteMagazine(issue, user.token));
    history.push("/magazine");
  };

  useEffect(() => {
    dispatchFormState({
      type: FORM_INPUT_UPDATE,
      value: issueDate ? issueDate.toString() : "",
      isValid: true,
      input: "date",
    });
  }, [issueDate]);

  // useEffect(() => {
  //   if (formState?.inputValues?.date) {
  //     const dateArray = formState?.inputValues?.date?.split(" ");

  //     const monthsArray = [
  //       "Jan",
  //       "Feb",
  //       "Mar",
  //       "Apr",
  //       "May",
  //       "Jun",
  //       "Jul",
  //       "Aug",
  //       "Sep",
  //       "Oct",
  //       "Nov",
  //       "Dec",
  //     ];

  //     const year = dateArray[3];
  //     const month =
  //       monthsArray.indexOf(dateArray[1]) < 9
  //         ? "0" + (monthsArray.indexOf(dateArray[1]) + 1).toString()
  //         : (monthsArray.indexOf(dateArray[1]) + 1).toString();
  //     const day = dateArray[2];
  //     setDate(day + "/" + month + "/" + year);
  //   }
  // }, [formState.inputValues.date]);
  console.log({ formState });
  return (
    <div className="editoffer__container">
      <div className="editoffer__header">
        <h1>Magazine Page</h1>
      </div>

      <form className="offerForm">
        {showForm && (
          <div className="editoffer__wrapper">
            <div className="editoffer__formContainer">
              <Input
                id="issue"
                label="Issue Number"
                type="number"
                required
                autoCapitalize="none"
                errorText="Please enter an issue number."
                onInputChange={inputChangeHandler}
                initialValue={business ? business.issue : ""}
                initiallyValid={!!business}
                updatedValue={business ? business.issue : ""}
              />
              <div className="inputlabel">Magazine Issue date</div>
              <DatePicker
                name="end"
                onChange={setIssueDate}
                value={issueDate}
                className="datePicker"
                minDate={new Date()}
                required
              />
              <Input
                id="town"
                label="Town"
                type="text"
                required
                autoCapitalize="none"
                errorText="Please enter a town."
                onInputChange={inputChangeHandler}
                initialValue={business ? business.town : ""}
                initiallyValid={!!business}
                updatedValue={business ? business.town : ""}
              />
              <div className="editoffer_upload_btn_wrapper">
                <span className="editoffer__imageLabel">PDF upload</span>
                <input
                  id="main_image"
                  type="file"
                  onChange={uploadHeroHandler}
                />
              </div>
              <div className="spacer"></div>
              <Button
                buttonSize="btn--small"
                buttonColor="green"
                onClick={submitHandler}
              >
                Add New Magazine
              </Button>
            </div>

            <div className="editoffer__formContainer">
              <div className="editoffer__table">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Issue</th>
                      <th>Date</th>
                      <th>Town</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {magazines.map((magazine) => (
                      <>
                        <tr className="tableRow" key={magazine.issue}>
                          <td className="tableCell">
                            <span>{magazine.issue}</span>
                          </td>
                          <td className="tableCell">
                            <span>{magazine.date}</span>
                          </td>
                          <td className="tableCell">
                            <span>{magazine.town}</span>
                          </td>
                          <td className="tableCell">
                            <Button
                              buttonSize="btn--small"
                              buttonColor="red"
                              onClick={deleteHandler(magazine.issue)}
                            >
                              X
                            </Button>
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default EditOffersScreen;
